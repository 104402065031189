<template>
  <div>

    <img class="stats-img" :src="'/card/' + gameId + '.jpg'" />      

    <div v-if="$route.query.thanks === null">
      <h1>Kiitos osallistumisestasi</h1>

      <div>
        <p class="share-link bold">Jaa nämä tulokset sosiaalisessa mediassa tällä linkillä:</p>
        <input id="share-link" @click="copy" class="link-share link-share-input-text" type="text" :value="shareUrl" />
        <button class="link-share" @click="copy">kopioi</button>
      </div>
    </div>
    <div v-else>
      <br>
      <StartQuizBtn :code="code" />
    </div>

    <!--
    <div style="margin-top: 50px;">
      Results {{ gameId }}<br>
      {{ answerCount }} vastausta<br>
      {{ correctCount }} oikein<br>
    </div>
    -->

    <div v-for="question, index in answers.data" :key="index">
      <Question :question="question.question" :userAnswer="parseInt(question.answer)" :code="code" />
    </div>

    <div v-if="$route.query.thanks !== null">
      <StartQuizBtn :code="code" />
    </div>

    <h2>Kilbailussa mukana:</h2>
    <img class="corp-group" :src="'/images/' + code + '/' + code + '.jpg'" />
  </div>
</template>

<script>
//import common from '@/mixins/common'
import Question from '@/components/ResultsQuestion'
import StartQuizBtn from '@/components/StartQuizBtn'

export default {
  name: 'Results',
  //mixins: [ common ],
  components: {
    Question,
    StartQuizBtn
  },
  data() {
    return {
      code: null,
      gameId: this.$route.params.gameId,
      shareUrl: process.env.VUE_APP_SHARE_LINK_PREFIX + '/' + this.$route.params.gameId,
      answers: {},
      answerCount: 0,
      correctCount: 0,
    }
  },
  methods: {
    copy() {
      var el = document.getElementById('share-link')
      el.select()
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(el.value)
        return
      }
      navigator.clipboard.writeText(el.value).then(function() {
        console.log('link copied to clipboard')
      }, function(err) {
        console.error('could not copy text: ', err)
      })
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea")
      textArea.value = text
      
      // Avoid scrolling to bottom
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    calculateStats() {
      for(var key in this.answers.data) {
        var userAnswer = this.answers.data[key].answer
        var qData = this.answers.data[key].question
        this.answerCount++
        if (userAnswer == qData.answer) {
          this.correctCount++
        }
      }
    }
  },
  mounted() {
    this.$api.post('/get-game-stats', {
      gameId: this.gameId
    }, (response) => {
      this.answers = response.data
      this.code = response.data.code
      this.calculateStats()
    })
  }
}
</script>

<style scoped>
.stats-img {
  width: 600px;
  margin-top: 20px;
}
.link-share-input-text {
  width: 370px;
}
.link-share {
  font-size: 1.2em;
}
.corp-group {
  max-width: 800px;
}
</style>
