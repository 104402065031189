<template>
  <div id="policy-container">
    <img class="gdpr-header" src="@/assets/gdpr-header.jpg" alt="header">
    <h1>Rekisteri- ja tietosuojaseloste</h1>

    <div id="policy">
    Tämä on yrityksen henkilötietolain (10 ja 24 §) ja EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Laadittu 18.04.2018. Viimeisin muutos 28.05.2018.<br>
    <br>
    <b>Rekisterin pitäjä:</b><br>
    <br>
    Mediatoimisto Attraction<br>
    Y-tunnus 2787233-5<br>
    Kissanmaankatu 8 A 3<br>
    33520 Tampere<br>
    <br>
    <b>Rekisteristä vastaava yhteyshenkilö:</b><br>
    <br>
    Kim Koskinen<br>
    kim.koskinen@attraction.fi<br>
    <br>
    <b>Rekisterin nimi:</b><br>
    <br>
    arvontarekisteri<br>
    <br>
    <b>Henkilötietojen käsittelyn tarkoitus:</b><br>
    <br>
    EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on:<br>
    <br>
    - Henkilön suostumus<br>
    - Arvonnan suorittaminen<br>
    - Palkintojen toimittaminen<br>
    <br>
    Tietoja ei luovuteta eteenpäin, eikä käytetä profilointiin.<br>
    <br>
    <b>Rekisterin tietosisältö:</b><br>
    <br>
    Rekisteriin tallennettavia tietoja ovat:<br>
    <br>
    - nimi<br>
    - postiosoite<br>
    - kotipaikkakunta<br>
    - sähköpostiosoite<br>
    - puhelinnumero<br>
    - IP-osoite<br>
    - istunnon tunniste<br>
    <br>
    Arvonnan jälkeen ainoastaan voittajien nimet sekä kotipaikkakunnat säilytetään rekisterissä ja muut tiedot poistetaan.<br>
    <br>
    <b>Säännönmukaiset tietolähteet:</b><br>
    <br>
    Rekisteröitävät tiedot kerätään rekisteröitäviltä itseltään.<br>
    <br>
    <b>Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle:</b><br>
    <br>
    Tietoja ei luovuteta säännönmukaisesti muille tahoille, eikä siirretä EU:n tai ETA:n ulkopuolelle. Voittajien nimet ja kotipaikkakunnat julkaistaan tällä sivustolla.<br>
    <br>
    <b>Rekisterin suojauksen periaatteet:</b><br>
    <br>
    Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti. Kun rekisteritietoja säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu. Rekisterinpitäjä huolehtii tarpeettomien henkilötietojen poistamisesta rekisteristä arvonnan jälkeen.<br>
    <br>
    <b>Tarkastusoikeus ja oikeus vaatia tiedon korjaamista:</b><br>
    <br>
    Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle. Pyynnön esittäjä joutuu todistamaan henkilöllisyytensä rekisterinpitäjälle. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).<br>
    <br>
    <b>Muut henkilötietojen käsittelyyn liittyvät oikeudet:</b><br>
    <br>
    Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä ("oikeus tulla unohdetuksi"). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle. Pyynnön esittäjä joutuu todistamaan henkilöllisyytensä rekisterin pitäjälle. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).<br>
    </div>
    <img class="gdpr-footer" src="@/assets/gdpr-footer.jpg" alt="footer">
  </div>
</template>

<script>
export default {
  name: 'Policy',
}
</script>

<style scoped>
#policy-container {
  margin: auto auto;
  width: 800px;
  text-align: left;
}
.gdpr-header, .gdpr-footer {
  margin: -20px;
  width: 105%;
}
.gdpr-footer {
  margin-top: 30px;
}
</style>
