<template>
  <div id="app-root">
    <img class="quiz-image" :src="quiz.image" :alt="code">

    <span v-if="quiz.state == 'running'">
      <h1>Tervetuloa Kilbailemaan!</h1>
      <p class="normal-text">Mukana {{ quiz.posessive }} ja lähiseutujen eri alojen rautaiset ammattilaiset. Osallistu ilmaiseksi ja voita upeita palkintoja.</p>
      <p class="normal-text">Kilbailussa on kaikkiaan {{ total }} kysymystä ja tarvitset {{ quiz.answers }} oikeaa vastausta tai kaikkiaan {{ total }} vastausta osallistuaksesi arvontaan.</p>
    </span>
    <span v-else>
      <WarningBox header="Kilbailu on päättynyt!" text="Kiitos kaikille osallistuneille." />
    </span>

    <StartQuizBtn v-if="quiz.state == 'running'" :code="code" />

    <div class="price-list">
      <div v-for="(item, index) in prizes" :key="index">
        <img class="cup" src="@/assets/cup.svg" alt="palkinto">
        <p class="large-text">{{ item.prize }}</p>
        <p class="large-text bold">{{ item.company }}</p>
      </div>
    </div>

    <StartQuizBtn v-if="quiz.state == 'running'" :code="code" />
      <h2>Vastausaika: {{ quiz.begins }} - {{ quiz.ends }}</h2>
      <p class="normal-text">Oulun ja lähiseutujen Kilbailussa {{ quiz.ptotal }} upeaa palkintoa!

         Olet mukana kaikkien {{ quiz.ptotal }} palkinnon arvonnassa, kun vastaat kaikkiin kysymyksiin tai vastaamalla kahteentoista kysymykseen oikein.

         Jokaisesta osallistuneesta tahosta on esillä yksi kysymys heidän toimintaansa liittyen.

         Logoa klikkaamalla aukeaa verkkosivu, josta löydät vastauksen kysymykseen.

         Yhteystietojasi ei luovuteta ulkopuolisille tai käytetä muuhun kuin palkinnonarvontaan.

         Voittajat ilmoitetaan Kilbailu.fi sivuilla ja heihin ollaan yhteydessä henkilökohtaisesti.

         Mediatoimisto Attraction vastaa palkintojen arpajaisverosta arvonnan toimeenpanijana.

         Tavoitteenamme on lisätä paikallisten toimijoiden tunnettavuutta hauskalla ja erilaisella tavalla.

         Tunnetko sinä {{ quiz.posessive }} ja lähiseutujen toimijat?
      </p>
    <StartQuizBtn v-if="quiz.state == 'running'" :code="code" />
    <img class="prize-logos" :src="'/images/'+code+'/'+code+'.jpg'" alt="Palkinnot luovuttaneet yritykset">
  </div>
</template>

<script>
import StartQuizBtn from '@/components/StartQuizBtn'
import WarningBox from '@/components/WarningBox'
import common from '@/mixins/common'

export default {
  name: 'Start',
  mixins: [ common ],
  components: {
    StartQuizBtn,
    WarningBox
  },
  data () {
      return {
        quiz: {},
        prizes: {},
        code: this.$route.params.code,
        total: 0
      }
  },
  methods: {
  },
  mounted() {
    window.scrollTo(0, 0)

    this.$api.cachedGet('/kilbailu-data', (response) => {
      this.quiz = response.data[this.code]
      if (this.quiz === undefined) {
          this.$router.push({ name: 'Home' })
          return
        }
        this.prizes = this.quiz.data.prizes
        this.total = Object.keys(this.quiz.questions).length
        this.quiz.begins = this.formatTime(this.quiz.begins)
        this.quiz.ends = this.formatTime(this.quiz.ends)      
    })

    /*
    this.waitData(() => {
      if (localStorage.getItem('quizData') != null) {
        this.quiz = JSON.parse(localStorage.getItem('quizData'))[this.$route.params.code]
        if (this.quiz === undefined) {
          this.$router.push({ name: 'Home' })
          return
        }
        this.prizes = this.quiz.data.prizes
        this.total = Object.keys(this.quiz.questions).length
        this.quiz.begins = this.formatTime(this.quiz.begins)
        this.quiz.ends = this.formatTime(this.quiz.ends)      
      }      
    })
    */
  },
}
</script>

<style scoped>
p {
  margin: 10px;
}
.warning {
  margin-top: -40px;
}
.price-list {
  margin-top: 20px;
}
.cup {
  width: 30px;
}
.prize-logos {
  max-width: 800px;
  margin-top: 30px;
}
.quiz-image {
  margin: -10px;
  margin-left: -20px;
  width: 105%;
}
@media screen and (max-width: 860px) {
  #app-root {
    margin: 0px;
  }
  .quiz-image {
    width: 100%;
  }
  .cup {
    width: 35px;
  }  
}
</style>
