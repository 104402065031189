import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Start from "@/views/Start.vue";
import Quiz from "@/views/Quiz.vue";
import ContactForm from "@/views/ContactForm.vue";
import Thanks from "@/views/Thanks.vue";
import Results from "@/views/Results.vue";
import Policy from "@/views/Policy.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:code",
    name: "Start",
    component: Start,
  },
  {
    path: "/quiz/:code",
    name: "Quiz",
    component: Quiz,
  },
  {
    path: "/contact",
    name: "ContactForm",
    component: ContactForm,
  },
  {
    path: "/thanks",
    name: "Thanks",
    component: Thanks,
  },
  {
    path: "/results/:gameId([a-z0-9]{13})",
    name: "Results",
    component: Results,
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
