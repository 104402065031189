<template>
  <div id="root">

    <router-link :to="{ name: 'Home' }">
        <img class="kilbailu-logo"  alt="Kilbailu logo" src="./assets/logo.png">
    </router-link>

    <div id="main-content" class="main-content border-new">

    <!--
    <div id="setup" class="normal-text" style="float: left; margin-left: 50px;">
      Kokeile taustakuvia:
      <select v-model="bg" @change="changeBg()">
        <option value="bg.jpg">bg 1</option>
        <option value="bg2.jpg">bg 2</option>
        <option value="bg3.jpg">bg 3</option>
        <option value="bg4.jpg">bg 4</option>
      </select><br>
      Kokeile kehyksiä:
      <select v-model="border" @change="changeBorder()">
        <option value="">1</option>        
        <option value="border-orig">2</option>
        <option value="border-new">3</option>
        <option value="border-funky">4</option>
        <option value="border-drawn">5</option>
      </select><br>
      <button @click="rmLocalStorage()">Tyhjennä localStorage ja päivitä sivu</button>
    </div>
    -->

      <router-view />
    </div>

    <table v-if="$route.name != 'Quiz'" id="footer">
      <tbody>
        <tr>
          <td><img id="footer-logo" src="@/assets/attraction-logo.png" alt="footer"></td>
          <td>
            <span id="footer-text">
              Mediatoimisto Attraction<br>
              Puh: 040 538 6084<br>
              asiakaspalvelu @ kilbailu.fi<br>
              Y-tunnus: 2787233-5
            </span>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      bg: 'bg3.jpg',
      border: 'border-new'
    };
  },  
  watch: {
      $route (to) {
        var el = document.getElementById('main-content')
        if (to.name == 'Quiz') {
          el.style['margin-bottom'] = '175px'
        } else {
          el.style['margin-bottom'] = '0px'
        }
      }
  },
  methods: {
    rmLocalStorage() {
      console.log('emptying localStorage')
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    },
    changeBg() {
      var body = document.getElementsByTagName('body')[0]
      body.style['background-image'] = 'url("/images/test/' + this.bg + '")'
    },
    changeBorder() {
      var el = document.getElementById('main-content')
      el.classList.remove('border-orig')
      el.classList.remove('border-new')
      el.classList.remove('border-funky')
      el.classList.remove('border-drawn')
      if (this.border != '') {
        el.classList.add(this.border)
      }
    }
  },
}
</script>

<style>
#setup {
  position: absolute;
  right: 0px;
  top: 0px;
  background: white;
  border: 1px solid black;
}
@font-face 
{ 
  font-family: "Luckiest Guy";
  src: url('~@/assets/fonts/LuckiestGuy-Regular.ttf');
}
.kilbailu-text {
  font-family: Luckiest Guy;
  font-size: var(--size);
  background: rgb(199,105,6);
  background: linear-gradient(45deg, rgba(199,105,6,1) 0%, rgba(255,178,15,1) 26%, rgba(255,234,16,1) 44%, rgba(255,237,195,1) 51%, rgba(255,234,16,1) 58%, rgba(255,178,15,1) 72%, rgba(199,105,6,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: var(--width);
}
a {
  text-decoration: none;
}
body {
  margin: 0px;
  background-color: #4d4d4d;
  background-image: url('~@/assets/bg3.jpg');
  background-attachment: fixed;
  background-size: cover;
}
.main-content {
  overflow: hidden;
  margin: auto auto;
  padding: 10px;
  width: 800px;
  border: 2px solid black;
  background-color: #fff;
  /*
  border: 15px solid transparent;
  border-image: url(~@/assets/border.png) 30 round;
  */

  /*
  border-top-left-radius: 57px 120px;
  border-top-right-radius: 153px 50px;
  border-bottom-left-radius: 40px 159px;
  border-bottom-right-radius: 220px 64px;

  border: 4px solid #6e7491;
  outline: 1px solid black;
  */
}
.border-orig {
  border: 15px solid transparent;
  border-image: url(~@/assets/border.png) 30 round;
}
.border-new {
  border-top-left-radius: 57px 120px;
  border-top-right-radius: 153px 50px;
  border-bottom-left-radius: 40px 159px;
  border-bottom-right-radius: 220px 64px;

  border: 4px solid #6e7491;
  outline: 1px solid black;  
}
.border-funky {
  border-top-width: 2px;
  border-right-width: 4px;
  border-bottom-width: 6px;
  border-left-width: 8px;
  border-top-style: solid;
  border-right-style: dotted;
  border-bottom-style: dashed;
  border-left-style: groove;
  border-top-color: #006100;
  border-right-color: #050116;
  border-bottom-color: #2ecc71;
  border-left-color: #3498db;  
}
.border-drawn {
  border:solid 5px #555;  
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;  
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.kilbailu-logo {
	width: 600px;
}
#footer {
  height: 120px;
  padding: 0px;
  min-width: 100%;
  background-color: #000;
  /*
  background: linear-gradient(45deg, rgba(199,105,6,1) 0%, rgba(255,178,15,1) 26%, rgba(255,234,16,1) 44%, rgba(255,237,195,1) 51%, rgba(255,234,16,1) 58%, rgba(255,178,15,1) 72%, rgba(199,105,6,1) 100%);  
  */
}
#footer-logo {
  float: right;
  width: 250px;
  margin-right: 10px;
}
#footer-text {
  float: left;
  color: white;
  text-align: left;
  margin-left: 10px;
  font-size: 1.3em;
}
.normal-text {
  font-size: 1.3em;
}
.large-text {
  font-size: 1.5em;
}
.bold {
  font-weight: bold;
}
.undecorated-link {
  text-decoration: none;
  color: #465265;
}
@media screen and (max-width: 860px) {
  #main-content {
    border-left: none;    
    border-right: none;
    width: 100vw;
    padding: 0px;
  }
  .kilbailu-logo {
    width: 90vw;
  }  
  .large-text {
    font-size: 2.2em;
  }
  .normal-text {
    font-size: 2em;
  }
  #footer {
    height: 175px;
  }  
  #footer-text {
    font-size: 1.5em;
  }
  #footer-logo {
    width: 300px;
  }
}
</style>
