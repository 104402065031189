<template>
  <div class="warning">
    <img class="attention-img" src="@/assets/attention.png">
    <div class="warning-message-text-wrap">
      <h1 class="warning-header">{{ header }}</h1>
      <p class="normal-text warning-text" v-html="text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarningBox',
  props: {
    header: String,
    text: String,
  }
}
</script>

<style>
.attention-img {
  width: 100px;
  float: left;
}
.warning {
  position: relative;
  margin: auto auto;
  min-width: 400px;
  width: fit-content;
  padding: 20px;
  border: 1px dotted #000;
  background-color: lightblue;
  border-radius: 5px;
  color: black;
}
.warning-header {
  font-size: 1.5em;
  margin: 0px;
}
.warning-text {
  margin-bottom: 0px;
}
.warning-message-text-wrap {
}
@media screen and (max-width: 860px) {
  .warning {
    width: 50vw;
  }
  .warning-header {
    font-size: 2em;
  }
  .attention-img {
    width: 12vw;
    min-width: 75px;
  }
}
</style>
