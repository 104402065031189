<template>
  <div>
    Thanks
  </div>
</template>

<script>
export default {
  name: 'Thanks',
}
</script>

<style scoped>
</style>
