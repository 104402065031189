import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import axios from "axios"
import api from '@/api'

/*
sessionStorage.removeItem('updated')
api.get('/kilbailu-data', (response) => {
  localStorage.setItem('quizData', JSON.stringify(response.data))
  sessionStorage.setItem('updated', Date.now())
})
*/

const app = createApp(App)
//app.config.globalProperties.$mobile = mobileAndTabletcheck()
app.use(router)
   .use(api)
   .mount('#app')
