import axios from "axios";

String.prototype.hashCode = function() {
  var hash = 0,
    i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const api = {

  responses: {},
  cache: {},

  install: (app) => {
    app.config.globalProperties.$api = api
    app.provide('api', api)
  },
  postFile: (path, data, callback, ...args) => {
    axios.post(process.env.VUE_APP_API_PREFIX + path, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }      
    })
    .then(response => {
      if (typeof callback == 'function') {
        callback(response, ...args)
      }
    })
    .catch(err => console.log('ajax error:', err))
  },
  post: (path, data, callback, ...args) => {
    axios.post(process.env.VUE_APP_API_PREFIX + path, data)
    .then(response => {
      if (typeof callback == 'function') {
        callback(response, ...args)
      }
    })
    .catch(err => console.log('ajax error:', err))
  },
  get: (path, callback, ...args) => {
    axios.get(process.env.VUE_APP_API_PREFIX + path)
    .then(response => {
      api.setCacheData(path, response)
      if (typeof callback == 'function') {
        callback(response, ...args)
      }          
    })
    .catch(err => console.log('ajax error:', err))
  },
  getCacheKey(path) {
    return 'api_cache_data_' + path.hashCode()
  },
  makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  getCacheData(path) {
    var cacheKey = api.getCacheKey(path)
    if (api.cache[cacheKey] !== undefined) {
      return api.cache[cacheKey]      
    }
    var data = sessionStorage.getItem(cacheKey)
    return JSON.parse(data)
  },
  setCacheData(path, data) {
    var cacheKey = api.getCacheKey(path)
    api.cache[cacheKey] = data
    sessionStorage.setItem(cacheKey, JSON.stringify(data))
  },
  updateCacheData(path, callback, ...args) {
    if (typeof callback == 'function') {
      var cacheData = api.getCacheData(path)
      cacheData = callback(cacheData, ...args)
      if (cacheData !== null) {
        api.setCacheData(path, cacheData)
      }
    }
  },
  updateAndRefreshData(path, callback, ...args) {
    setTimeout(api.cachedGet(path), 0)
    api.updateCacheData(path, callback, ...args)
  },
  cachedGet(path, callback, ...args) {
    var cacheKey = api.getCacheKey(path)
    //console.log('API cacheKey:', cacheKey)
    var cacheResponse = api.getCacheData(path)
    var requestKey = api.makeId(10)

    setTimeout((requestKey, ...args) => {
      api.get(path, (response, requestKey, ...args) => {
        api.setCacheData(path, response)
        if (api.responses[requestKey] !== true) {
          delete api.responses[requestKey]
          if (typeof callback == 'function') {
            response.type = 'api'
            response.key = cacheKey
            response.id = requestKey
            callback(response, ...args)  
          }
        }
      }, requestKey, ...args)
    }, 0, requestKey, ...args)

    if (cacheResponse !== null && typeof callback == 'function') {
      api.responses[requestKey] = true
      cacheResponse.type = 'cache'
      cacheResponse.key = cacheKey
      cacheResponse.id = requestKey
      callback(cacheResponse, ...args)
    }
  }
}

export default api;