<template>
	<button :disabled="activate()" class="start-btn" @click="startQuiz">
    <img class="btn-image" src="@/assets/quiz-start.png" alt="Aloita kilbailu"> 
  </button>
</template>

<script>
import common from '@/mixins/common'

export default {
  name: 'StartQuizBtn',
  mixins: [ common ],
  props: {
    code: {
      type: String,
      required: false
    },
    forceLoad: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    activate() {
      if (this.code === null) {
        return 'disabled'
      } else {
        return null
      }
    },
    startQuiz() {
      //if (localStorage.getItem(this.code + '_state') === null || confirm('Haluatko jatkaa keskeneräistä kilbailua?')) {
      if (localStorage.getItem('savedState') === null || confirm('Haluatko jatkaa keskeneräistä kilbailua?')) {
        // in case we are continuing from the different starting page than our saved state
        var code = localStorage.getItem('savedState')
        if (code === null) {
          code = this.code
        }
        this.$router.push({ name: 'Quiz', params: { code: code }})
      } else {
        this.$router.push({ name: 'Quiz', params: { code: this.code }, query: { new: null }})        
      }
    },
  }  
}
</script>

<style scoped>
.start-btn {
  font-size: 1.3em;
  font-weight: bold;
  color:              #000000;
  border-radius: 5px;  
  padding: 8px;
}
/*
.start-btn {
  margin: 20px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 20px;
  border: 3px solid #512f51;
  padding-top: 10px;
  color: #000000;
  background-color: #F6C2F4;
  box-shadow: #FFFFFF 3px 3px 3px  inset;
  background-image: linear-gradient(to bottom, #F6C2F4, #D0D0D0);
}
*/
.btn-image {
  width: 400px;
}
.start-btn:disabled {
  border: 2px solid red;
  background-color: gray;
}
@media screen and (max-width: 860px) {
  .btn-image {
    width: 70vw;
  }
}
</style>
