/<template>
  <div id="app-root">

    <div ref="loadScreen" class="load-screen">
      <img class="k" src="@/assets/k-blue.png" alt="K">
      <p class="load-text">Ladataan kilbailua...<br>{{ loaderMessage }}</p>
      <div class="spinner"></div>
      <!-- <div class="loader-message">{{ loaderMessage }}</div> -->
    </div>

    <p class="contact-link" v-show="showContactLink()">
      <router-link :to="{ name: 'ContactForm' }">
        <img ref="contact-link-text" class="contact-link-text" src="@/assets/to-contact-form.png" alt="jätä yhteystiedot ja osallistu arvontaan">
        <img ref="contact-link-img" class="contact-link-img" src="@/assets/cup.svg" />
      </router-link>
    </p>

    <!--
    <div class="settings-button">
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" :stroke="settingsStrokeColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
    </div>
    -->

    <div class="points-meter-container">
      <PointsMeter ref="pointsMeter" />
    </div>

    <swiper
      class="swiper-container"
      ref="questionContainer"
      :slides-per-view="1"
      :space-between="0"
      :pagination="{
        el: '.questions-pagination',
        clickable: true
      }"
      :navigation = "{
        nextEl: '.questions-next',
        prevEl: '.questions-prev'
      }"
      :modules="modules"
      @slideChange="slideChange"
      @swiper="onSwiper">

      <swiper-slide v-for="question, index in quiz.questions" :key="question.id" :virtual-index="index">
          <Question ref="question" :code="code" :question="question" @checkAns="checkAns" />
      </swiper-slide>

      <div class="swiper-pagination questions-pagination"></div>

      <div ref="swiperPrev" class="swiper-button-prev questions-prev"></div>
      <div ref="swiperNext" class="swiper-button-next questions-next"></div>
    </swiper>

    <!--
    <div v-show="isOffTheRails() && !answerLock" id="off-the-rails" @click="current()">
      <img v-if="!preview" @click="current()" class="off-the-rails-text" src="@/assets/return-to-current-pos.png">
    </div>
    -->

    <div id="banner-container">
      <img id="banner-img" src="" alt="palkintokuva">
    </div>        
  </div>
</template>

<script>
import { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'
import "swiper/css/pagination";
import "swiper/css/navigation";

import PointsMeter from '@/components/PointsMeter.vue'
import Question from '@/components/Question.vue'
import common from '@/mixins/common.js'

window.performance = window.performance || {};
performance.now = (function() {
    return performance.now       ||
        performance.mozNow    ||
        performance.msNow     ||
        performance.oNow      ||
        performance.webkitNow ||            
        Date.now  /*none found - fallback to browser default */
})();

export default {
  name: 'Quiz',
  mixins: [ common ],
  components: {
    Swiper,
    SwiperSlide,
    Question,
    PointsMeter
  },
  data () {
    return {
      quiz: {
        data: {
          answers: 12
        }
      },
      preview: false,
      code: this.$route.params.code,
      forceNew: this.$route.query.new,
      gameId: 0,
      total: 0,
      position: 0,
      points: 0,
      answerCount: 0,
      // answers object structure:
      // answers[question id]: given answer id
      answers: {},
      answerLock: false,
      navigationLock: false,
      bannerTimeouts: [],
      savedStateLoaded: false,
      perfDiff: 0,
      cancel: false,
      loadLoopCount: 0,
      loaderMessage: this.$route.params.code,
      settingsStrokeColor: '#cccccc',
      answerStart: 0
    }
  },
  setup() {

    const swiperOptions = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: '.questions-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.questions-next',
        prevEl: '.questions-prev'
      },
    }

    return {
      swiperOptions,
      modules: [Pagination, Navigation],
    };
  },
  methods: {
    showContactLink() {
      if (this.points >= this.quiz.data.answers) {
        setTimeout(() => {
          this.$refs['contact-link-text'].style.width = 0
          setTimeout(() => {
            this.$refs['contact-link-text'].style.display = 'none'
          }, 500)
        }, 3000)
        return true
      } else {
        return false
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper
    },
    slideChange(swiper) {
      this.position = swiper.activeIndex
      this.setBanner()      
      this.setQuestionHeight()

      this.answerStart = performance.now()
    },
    isOffTheRails() {
      var diff = Math.abs(this.position - this.getCurrentPos())
      if (diff > 0) {
        return true
      }
      return false
    },
    clearAllTimeouts() {
      for (var i = 0; i < this.bannerTimeouts.length; i++) {
        clearTimeout(this.bannerTimeouts[i]);
        this.bannerTimeouts.splice(i, 1)
      }
    },
    prev() {
      // this.swiper.slidePrev() ???
      this.position--
      this.scroll()
    },
    next() {
      // this.swiper.slideNext()
      this.position++
      this.scroll()
    },
    getCurrentPos() {
      if (typeof this.quiz.questions != 'undefined') {
        // find first unanswered question
        var pos = 0
        for(var i = 0; i < this.quiz.questions.length; i++) {
          if (this.answers[this.quiz.questions[i].id] === undefined) {
            return pos
          }
          pos++
        }        
      }
      return false
    },
    // set position to first unanswered question
    current() {
      var pos = this.getCurrentPos()
      if (pos === false) {
        this.$router.push({ name: 'ContactForm' })                
      } else {
        this.position = pos
        this.scroll()
      }
    },
    setQuestionHeight() {
      var el = this.$refs.questionContainer.$el
      if (this.$refs.question !== undefined) {
        el.style.height = (this.$refs.question[this.position].$el.offsetHeight + 10) + 'px'
      }
    },
    scroll() {
      this.setBanner()
      this.swiper.slideTo(this.position)
      this.answerLock = false
      this.setQuestionHeight()
    },
    hideLoadScreen() {
        //var element = document.getElementById('load-screen')
        var element = this.$refs.loadScreen
        var op = 1;  // initial opacity
        var timer = setInterval(function () {
            if (op <= 0.1){
                clearInterval(timer);
                element.style.display = 'none';
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op -= op * 0.2;
        }, 50);
        this.init = true
    },
    showLoadScreen() {
      //var element = document.getElementById('load-screen')
      var element = this.$refs.loadScreen
      element.style.display = 'block';
      element.style.opacity = 1;
    },
    saveState() {
      console.log('saving session')
      // just save everything to get exactly same quiz
      // it's not optimal, but it's shouln't be too heavy operation either
      var state = {
        'gameId': this.gameId,
        'answers': this.answers,
        'preview': this.preview,
        //'requiredPoints': this.quiz.answers,
        'answerCount': this.answerCount,
        'points': this.points,
        'position': this.position,
        'quiz': this.quiz
      }
      //console.log('save length:', JSON.stringify(state).length)
      localStorage.setItem(this.code + '_state', JSON.stringify(state))
      localStorage.setItem('savedState', this.code)
      localStorage.setItem('savedStateImage', this.quiz.image)
    },
    loadState() {
      if (this.forceNew === undefined &&
          localStorage.getItem('savedState') !== null &&
          localStorage.getItem('savedState') == this.code) {

        var state = JSON.parse(localStorage.getItem(this.code + '_state'))
        if (state !== null) {
          console.log('loading session')

          if (state.preview === true) {
            this.preview = true
            this.loaderMessage = `${this.loaderMessage} esikatselu`
          }
          //this.preview = (state.preview === true) ? true : false
          this.gameId = state.gameId
          this.answers = state.answers
          this.answerCount = state.answerCount
          // we already have quiz-data but rewriting anyway
          // to get exactly same question order
          this.quiz = state.quiz

          // continue from next position
          // ...unless the last question
          this.position = state.position
          if (this.position >= this.quiz.questions.length) {
            this.position = (this.quiz.questions.length - 1)
          }

          this.total = this.quiz.questions.length
          this.points = state.points
          for(var questionId in this.answers) {
            setTimeout(this.markAnswers, 100, questionId)
          }
          this.$refs.pointsMeter.setPoints(this.points)

          console.log('saved state loaded')

          this.savedStateLoaded = true
          return true
        }
      }
      this.savedStateLoaded = true
      return false
    },
    checkAns(questionId, aindex) {
      console.log(questionId, aindex)
      // prevent increasing points by spamming single answer
      if (this.answerLock === true) return
      this.answerLock = true

      var duration = performance.now() - this.answerStart

      // remember answers
      if (this.answers[questionId] !== undefined) {
        this.current()
        return
      }
      this.answers[questionId] = aindex
      this.answerCount++

      if (this.quiz.questions[this.position].answer == aindex) {
        console.log('correct answer')
        this.points++
        this.$refs.pointsMeter.increasePoint()
      } else {
        console.log('wrong answer, correct was:', this.quiz.questions[this.position].answer)
      }

      this.$api.post('/answer', {
        question: questionId,
        answer: aindex,
        gameId: this.gameId,
        duration: duration
      }, (response) => {
        console.log(response.data)
      })

      this.markAnswers(questionId)
      this.saveState()

      setTimeout(this.current, 500)
    },
    createCheckImg(correct) {
      var img = document.createElement('img')
      img.classList.add('check-img')
      if (correct === true) {
        img.src = require('@/assets/correct.png')
      } else {
        img.src = require('@/assets/wrong.png')
      }
      return img
    },
    markAnswers(questionId) {

      var correct = false
      var question = this.quiz.questions[this.qid2pos(questionId)]
      var answer = this.answers[questionId]

      for(var i in question.answers) {

        var el = document.getElementById('ans-opt-' + questionId + '-' + i)

        if (el !== null && question.answer == i) {
          el.classList.add('option_correct')
        } else {
          el.classList.add('option_incorrect')
        }

        if (i == answer) {

          if (answer == question.answer) {
            correct = true
          } else {
            correct = false
          }

          var img = this.createCheckImg(correct)
          img.id = 'check-img-' + questionId
          el.appendChild(img)
        }
      }
    },
    // position pointer to question id converter
    pos2qid(pos) {
      if (pos === undefined) {
        pos = this.position
      }
      if (typeof this.quiz.questions != 'undefined' && pos < this.quiz.questions.length) {
        return this.quiz.questions[pos].id
      } else {
        return false
      }
    },
    // question id to position pointer converter
    qid2pos(qid) {
      for(var question in this.quiz.questions) {
        if (this.quiz.questions[question].id == qid) {
          return question
        }
      }
      return false
    },
    getBanner() {
      if (typeof this.quiz.code == 'undefined') return ''
      var banner = ''
      var banners = []
      if (typeof this.quiz.questions != 'undefined') {
        if (this.position < this.total) {
          banners = this.quiz.questions[this.position].banners
        }
      }
      if (typeof banners != 'undefined' && banners != null && banners.length > 0) {
        banner = banners[Math.floor(Math.random()*banners.length)]
      } else if (typeof this.quiz.banners != 'undefined' && this.quiz.banners != null && this.quiz.banners.length > 0) {
        banner = this.quiz.banners[Math.floor(Math.random()*this.quiz.banners.length)]
      }
      if (banner.charAt(0) == '/') {
        return banner
      } else {
        return '/images/' + this.quiz.code + '/banners/' + banner        
      }
    },
    setBanner() {
      //console.log('setBanner()', this.bannerTimeout)
      if (this.$route.name == 'Quiz') {
        var el = document.getElementById('banner-img')
        el.src = this.getBanner()

        // make sure that only one timeout call is waiting
        this.clearAllTimeouts()
        this.bannerTimeouts.push(setTimeout(this.setBanner, 5000))
      }
    },
    /*
    _reallyLoaded(callback, ...args) {
      if (this.savedStateLoaded !== true) {
        setTimeout(this.reallyLoaded, 10, callback, ...args)
        return
      }
      if (this.$refs.question === undefined) {
        console.log('waiting questions')
        setTimeout(this.reallyLoaded, 10, callback, ...args)
        return
      }
      var el = this.$refs.question[this.position].$el
      console.log('page fully loaded')
      if (el.offsetHeight > 0) {
        callback(...args)
      } else {
        setTimeout(this.reallyLoaded, 10, callback, ...args)
      }
    },
    */
    reallyLoaded(callback, ...args) {
      if (++this.loadLoopCount > 10000) {
        return
      }
      if (this.$refs.question === undefined || this.$refs.question[this.position].$refs.questionImg === undefined) {
        setTimeout(this.reallyLoaded, 10, callback, ...args)
        return
      }
      var imgEl = this.$refs.question[this.position].$refs.questionImg
      var qEl = this.$refs.question[this.position].$el
      if (imgEl.offsetHeight == 0 || qEl.offsetHeight == 0) {
        setTimeout(this.reallyLoaded, 10, callback, ...args)
        return
      }
      console.log('page fully loaded')
      callback(...args)        
    },    
    checkAllAnswered() {
      if (Object.keys(this.answers).length == this.quiz.questions.length) {
        this.$router.push({ name: 'ContactForm' })
      }
    },
    apiStart() {
      if (this.forceNew === null ||
          localStorage.getItem('savedState') === null ||
          localStorage.getItem('savedState') != this.code) {
        this.$api.post('/start', {
          code: this.code
        }, (response) => {
          if (response.data !== false && response.data.gameId !== undefined) {
            this.gameId = response.data.gameId
            this.saveState()          
          } else {
            this.loaderMessage = `Kilbailua ${this.code} ei löydy`
            setTimeout(this.$router.push, 3000, { name: 'Home' })
          }
        })
      }      
    },
    loadQuiz() {
      if (!this.loadState()) {
        this.$api.cachedGet('/kilbailu-data', (response) => {
          let quiz = response.data[this.code]
          if (quiz !== undefined) {
            this.quiz = quiz
            //this.setBanner()
            this.total = Object.keys(this.quiz.questions).length
            this.quiz.begins = this.formatTime(this.quiz.begins)
            this.quiz.ends = this.formatTime(this.quiz.ends)
          } else {
            console.log('no quiz')
            this.loaderMessage = `Kilbailun ${this.code} lataus ei onnistu`
            setTimeout(this.$router.push, 3000, { name: 'Home' })          
          }
        })
      }      
    }
  },
  created() {
    window.addEventListener("resize", this.setQuestionHeight)
    window.addEventListener("load", this.setQuestionHeight)
  },
  unmounted() {
    window.removeEventListener("resize", this.setQuestionHeight)
    window.removeEventListener("load", this.setQuestionHeight)
  },  
  mounted() {

    if (this.mobileAndTabletcheck()) {
      this.$refs.swiperNext.style.display = 'none'
      this.$refs.swiperPrev.style.display = 'none'
    }

    this.showLoadScreen()
    this.apiStart()
    this.loadQuiz()

    this.reallyLoaded(() => {
      this.checkAllAnswered()
      // little extra time for slow browsers
      setTimeout(() => {
        if (Object.keys(this.answers).length > 0) {
          this.current()
        } else {
          this.scroll()
        }
        this.setQuestionHeight()        
        this.hideLoadScreen()
      }, 200)
    })
  },
  beforeUnmount() {
    this.clearAllTimeouts()
  }  
}
</script>

<style>
:root {
  --swiper-navigation-size: 75px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-pagination-bullet-size: 7px;
}
#app-root {
  position: relative;
}
.points-meter-container {
  position: relative;
}
.settings-button {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  z-index: 10;
}
.questions-next {
  top: 400px;
  right: 50px;
  color: #f2f2f2;
}
.questions-prev {
  top: 400px;
  left: 50px;
  color: #f2f2f2;
}
.questions-next:hover, .questions-prev:hover {
  color: #999999;
}
.swiper-container {
    overflow: visible;
}
.swiper-pagination {
}
.questions-pagination {
  position: relative;
  margin-top: 20px;
  margin-bottom: -30px;
  /*
   * pagination at top
   *
  overflow: visible;
  position: absolute;
  top: -13px;
   *
   */
}
.swiper-pagination-bullet-active {
  background-color: #000 !important;
}
#off-the-rails {
  position: fixed;
  bottom: 150px;
  right: 5px;
  z-index: 5;
  margin-bottom: 10px;
  text-align: right;
  background: rgb(2,0,36);
  background: linear-gradient(-45deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  border-radius: 20px;
  border: 1px solid black;
  padding: 5px;
  cursor: pointer;
}
.off-the-rails-text {
  width: 300px;
  cursor: pointer;
  margin: 5px;
}
.check-img {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
}
.swiper {
  scroll-behavior: smooth;
  transition: height 0.2s;  
}
.swiper {
  /* overflow: hidden; */
  position: relative;
}
/* load-screen */
.load-screen{
  position: fixed;
  top: 0px;
  left: 0px;
  display: none;
  opacity: 1;
  /* background-color: #2a9d8f; */
  background-color: #7c91f9;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.k, .load-text {
  position: relative;
  top: 20%;
  left: 0%;
}
.load-text {
  font-weight: bold;
  font-size: 3em;
  color: #23358a;
}
.spinner {
  position: relative;
  top: 20%;
  margin: auto;
  border: 10px solid #EAF0F6;
  border-radius: 50%;
  border-top: 10px solid #23358a;
  width: 100px;
  height: 100px;
  animation: _spinner 1s linear infinite;
}
@keyframes _spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader-message {
  position: relative;
  top: 25%;
  font-size: 2em;
  font-weight: bold;
  color: #23358a;  
}
/**************************/
#banner-img {
  width: 800px;
}
.contact-link {
  position: fixed;
  right: 5px;
  bottom: -10px;
  z-index: 5;
  background: rgb(2,0,36);
  background: linear-gradient(-45deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  border-radius: 20px;
  border: 1px solid black;
  padding: 5px;
}
.contact-link-text {
  width: 330px;
  height: 100px;
  transition: width 0.5s;
}
.contact-link-img {
  width: 100px;
}
@media screen and (max-width: 860px) {
  #app-root {
    margin: 10px;
  }
  .questions-next {
    display: none;
  }
  .questions-prev {
    display: none;
  }
  .questions-pagination {
    position: relative;
    margin-top: 20px;
  }  
  .check-img {
    width: 7vw;
  }  
  #off-the-rails {
    bottom: 20vw;
  }
  .off-the-rails-text {
    width: 50vw;
  }
  .contact-link-text {
    width: 60vw;
    height: 20vw;
  }
  .contact-link-img {
    width: 20vw;
  }
  #banner-img {
    margin-top: 20px;
    width: 95vw;
  }  
}
</style>