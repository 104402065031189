<template>
<div id="form-container">

<h1>Kiitos osallistumisestasi! Täyttämällä yhteystiedot olet mukana kilbailussa!</h1>

  <span v-if="!runningQuizEnded()">
    <router-link :to="{ name: 'Quiz', params: { 'code': this.code }, query: { forceLoad: true }}">
      <img class="continue-quiz-link" src="@/assets/continue.png" alt="jatka kilbailua">
    </router-link>
  </span>

  <h2>Onnea palkintojen arvontaan!</h2>

  <p id="first">Etunimi:</p>
  <div id="firstbg">
    <input  type="text"
        class="cinfo_input_text required"
        id="firstname"
        v-model="firstname"
        placeholder="etunimi"
        @input="clearErrors($event)">
    <br>
  </div>

  <p id="last">Sukunimi:</p>
  <div id="lastbg">
    <input  type="text"
        class="cinfo_input_text required"
        id="lastname"
        v-model="lastname"
        placeholder="sukunimi"
        @input="clearErrors($event)">
    <br>
  </div>

  <h2>Katuosoite:</h2>
  <input type="text" class="cinfo_input_text" v-model="address" placeholder="katuosoite" >
  <br>

  <h2>Postinumero:</h2>
  <input type="text" class="cinfo_input_text" v-model="zip" placeholder="postinumero">
  <br>

  <h2>Postitoimipaikka:</h2>
  <input type="text" class="cinfo_input_text" v-model="city" placeholder="postitoimipaikka">
  <br>

  <h2>Sähköposti:</h2>
  <input type="text" class="cinfo_input_text" v-model="email" placeholder="sähköpostiosoite">
  <br>

  <p>Puhelin:</p>
  <div id="phonebg">
    <input  type="text"
        class="cinfo_input_text required"
        id="phone"
        v-model="phone"
        placeholder="puhelinnumero"
        @input="clearErrors($event)">
    <br>
  </div>

  <h2>Palautetta Kilbailusta:</h2>
  <textarea class="cinfo_input_text cinfo_textarea" rows="2" v-model="feedback" placeholder="muuta palautetta..." maxlength="1024"></textarea>
  <br>

  <p class="large-text bold">
    Minulle ei saa lähettää sähköpostia tulevista kilbailuista
    <input class="email-permission" type="checkbox" v-model="email_permission">
  </p>

  <span class="normal-text">
    Lähettämällä lomakkeen annat suostumuksesi henkilötietojen säilyttämiseen
    arvonnan suorittamisen ajaksi ja hyväksyt rekisteri- ja tietosuojaselosteen
    mukaisen tietojen käsittelyn.
  </span>

  <h2>Piditkö Kilbailusta?</h2>
  <table class="thumbs">
    <tr>
      <td>
        <button id="upvote" @click="submit('up')"></button>
      </td>
      <td>
        <button id="downvote" @click="submit('down')"></button>
      </td>
    </tr>
  </table>
  <span class="normal-text">Lähetä lomake peukulla!</span><br><br>
  <div class="policy-link">
    <router-link :to="{ name: 'Policy'}" class="large-text undecorated-link">
      Rekisteri- ja tietosuojaseloste
    </router-link>
  </div>
  <span id="infotext"></span>
</div>
</template>

<script>
import common from '@/mixins/common'

export default {
  name: 'ContactForm',
  mixins: [ common ],
  /*
  props: {
    code: {
      type: String,
      required: true
    }
  },
  */
  data() {
    return {
      code: localStorage.getItem('savedState'),
      firstname: '',
      lastname: '',
      address: '',
      zip: '',
      city: '',
      phone: '',
      email: '',
      feedback: '',
      email_permission: false,
      gameId: 0
    }
  },
  methods: {
    // returns false if there is no started quiz or current one run out of the questions
    runningQuizEnded() {
      var state = JSON.parse(localStorage.getItem(this.code + '_state'))
      if (state === null || state.answerCount == state.quiz.questions.length) {
        return true
      } else {
        return false
      }
    },
    // requirements are 12 correct answers or answering all the questions
    quizQualification() {
      var state = JSON.parse(localStorage.getItem(this.code + '_state'))
      if (state === null) {
        return false
      }
      if (state.answerCount == state.quiz.questions.length || state.points >= state.quiz.data.answers) {
        this.gameId = state.gameId
        return true
      } else {
        return state.quiz.code
      }
    },
    submit(vote) {
      if (this.checkErrors()) {
        console.log('ok')
        this.$api.post('/contacts', {
          gameId: this.gameId,
          code: this.code,
          firstname: this.firstname,
          lastname: this.lastname,
          address: this.address,
          zip: this.zip,
          city: this.city,
          phone: this.phone,
          email: this.email,
          feedback: this.feedback,
          email_permission: this.email_permission,
          vote: vote
        }, (response) => {
          if (response.data === true) {
            this.removeSavedState(this.code)
            //this.$router.push({ name: 'Thanks' })
            this.$router.push({ name: 'Results', params: { gameId: this.gameId }, query: { thanks: null }})
          }
        })
      } else {
        console.log('errors')
      }
    },
    checkErrors() {
      var el
      var error = false
      // order matters, scroll to highest element
      // reverse order on page
      if (this.phone == '') {
        error = true
        el = document.getElementById('phone')
        el.classList.add('error')
      }
      if (this.lastname  == '') {
        error = true
        el = document.getElementById('lastname')
        el.classList.add('error')
      }
      if (this.firstname == '') {
        error = true
        el = document.getElementById('firstname')
        el.classList.add('error')
      }

      if (el !== undefined) {
        if (typeof window.scroll == 'function') {
          const y = el.getBoundingClientRect().top + window.scrollY - 400;
          window.scroll({ top: y, behavior: 'smooth' })
        } else {
          // window.scroll({ ...options }) is not supported on IE, Edge and Safari.
          // scrollIntoView() is supported on IE 6. Probably unecessary, since this
          // most likely won't work on IE anyway.
          el.scrollIntoView()
        }
      }
      return !error
    },
    clearErrors(e)
    {
      e.target.classList.remove('error')
    },
  },
  created() {
    var code = this.quizQualification()
    if (code !== true) {
      // not qualified to partisipate yet
      console.log('not qualified', code)
      if (code === false) {
        // no running quiz => front page
        this.$router.push({ name: 'Home' })
      } else {
        // back to running quiz
        this.$router.push({ name: 'Quiz', params: { 'code': code }})
      }
    }
  }
}
</script>

<style scoped>
h2 {
  color: #50044f;
}  
#form-container {
  width: 800px;
}
#upvote {
  width:  105px;
  height: 105px;
  background: transparent url('~@/assets/thumb_up.png');
  background-repeat: no-repeat;
  background-size: 75px 75px;
  background-position: center;
}
#downvote {
  width:  105px;
  height: 105px;
  background: transparent url('~@/assets/thumb_down.png');
  background-repeat: no-repeat;
  background-size: 75px 75px;
  background-position: center;
}
.thumbs {
  margin: auto auto;
  background: transparent;
  border:   none;
  outline:  none;
}
.thumbs button:hover {
  cursor: pointer;
  transform:  scale(0.9);
}
.thumbs td {
  text-align: center;
  padding: 15px;
}
.cinfo_input_text {
  color: #c56800;
  border: 3px solid #c56800;
  border-radius: 6px;
  padding: 15px;
  margin: 15px;
  box-shadow: 0 4px 1px rgba(0, 0, 0, 0.2), inset 0 2px 1px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, #fffbf7 47%,#ede5da 100%);

  width: 80%;
  font-family: "Oswald";
  font-size: 1.5em;
  text-transform: none;
}
.cinfo_textarea {
  height: 100px;
  width: 80%;
  font-family: "Oswald";
  text-transform: none;  
}
.error {
  border: 4px solid red;
}
.email-permission {
  width: 30px;
  height: 30px;
}
.email-permission {
  vertical-align: sub;  
}
@media screen and (max-width: 860px) {
  .cinfo_input_text {
    font-size: 4vw;
  }
  .email-permission {
    width: 50px;
    height: 50px;
  }
  .continue-quiz-link {
    width: 50vw;
  }
}
</style>
