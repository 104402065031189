<template>
  <div>
    <table class="question">
      <tbody>
        <tr>
          <td class="logo-td">
            <a :href="question.url" target="_blank">
              <!-- <img class="logo-img" :src="'/images/' + code + '/logos/' + question.image" /> -->
              <img class="logo-img" :src="question.image" />
            </a>
          </td>
        </tr>
        <tr>
          <td class="question-td">
            <h3><span v-html="question.question" /></h3>
            <ul class="answer-list">
              <li :class="getAnswerClass(index, userAnswer, question.answer)" v-for="answer, index in question.answers" :key="index">
                {{ answer }}
              </li>
            </ul>
            <img v-if="userAnswer == question.answer" class="results-check-img" src="@/assets/correct.png" />
            <img v-else class="results-check-img" src="@/assets/wrong.png" />
          </td>
        </tr>
      </tbody>
    </table>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'ResultsQuestion',
  props: {
    question: {
      type: Object,
      required: true
    },
    userAnswer: {
      type: Number,
      required: true
    },
    code: {
      type: String,
      required: true
    }
  },
  methods: {
    getAnswerClass(answerIndex, userAnswer, correctAnswer) {
      if (answerIndex == correctAnswer) {
        return 'correct-answer bold'
      } else {
        if (answerIndex == userAnswer) {
          return 'wrong-answer bold'
        }
      }
    }
  }
}
</script>

<style scoped>
.logo-img {
  width: 300px;
}
.logo-td {
  vertical-align: top;
}
.question-td {
  text-align: left;
}
.answer-list {
  list-style-type: numbered;
}
.question {
  position: relative;
  width: 500px;
  margin: auto auto;
  font-size: 1.2em;
}
.correct-answer {
  color: green;
}
.wrong-answer {
  color: red;
}
@media screen and (max-width: 860px) {
  .question {
    font-size: 1.5em;
  }
}
.results-check-img {
  position: absolute;
  right: -20px;
  bottom: 0px;
  width: 50px;
}
</style>
