<template>
  <div id="app-root">
    <router-link :to="{ name: 'Policy'}">
      <img class="nav-top-img" src="@/assets/gdpr.png" alt="GDPR">
    </router-link>
    <!-- <h1>TERVETULOA KILBAILEMAAN. OSALLISTU JA VOITA UPEITA PALKINTOJA!</h1> -->

    <p style="clear: right; font-size: 3em;" class="kilbailu-text">Tervetuloa Kilbailemaan! Osallistu ja voita upeita palkintoja!</p>

    <span v-if="runningQuizNotEnded()">
        <h2 id="save-header" class="save-header">Jatka keskeneräistä:</h2>

        <div class="save-container">
          <router-link :to="{ name: 'Quiz', params: { code: savedState }}">
            <!-- <img id="in-progress-ribbon" class="in-progress-ribbon" alt="kesken" src="@/assets/in-progress-2.png"> -->
            <img :id="'save-' + savedState" class="quiz-image" :src="savedStateImage" :alt="savedState">
            <img id="save-overlay" class="quiz-overlay quiz-image" src="@/assets/save-overlay.png" alt="kesken" />
            <img v-if="preview" id="preview" class="quiz-overlay quiz-image" src="@/assets/preview.png" alt="preview" />
          </router-link>
          <img id="save-close" src="@/assets/wrong.png" class="remove" alt="poista" @click="rmSave(savedState)">
          <br>
          <span class="stats">
            {{ savedStats.points }}/12 vastausta oikein ja
            {{ savedStats.answers }}/{{ savedStats.total }} vastausta kaikkiaan
          </span>
        </div>
    </span>

    <!--
    <div id="qualified-warning" v-if="isQualified()">
      <router-link :to="{ name: 'ContactForm'}">
        <WarningBox header="Et ole vielä osallistunut arvontaan" text='Vain jättämällä yhteystietosi voit osallistua arvontaan<br><img src="/images/common/ok.png" style="width: 75px; margin-top: 10px;" alt="ok">' />
      </router-link>
    </div>
    -->

    <h2 v-if="!hasRunningQuiz()">Uusia kilbailuita on pian taas tulossa.</h2>
    <h2 v-else>Käynnissä olevat kilbailut:</h2>
    <div v-for="quiz in qlist" :key="quiz.code">
      <span v-if="quiz.state == 'running'">
        <router-link :to="{ name: 'Start', params: { code: quiz.code }}">
          <img class="quiz-image" :src="quiz.image" :alt="quiz.code">
        </router-link><br>
        <span :class="getRemainingTimeStyle(quiz.code)">
          <span class="large-text bold">{{ quiz.timeRemainingFormatted.time }}</span>
          <span class="normal-text">&nbsp;{{ quiz.timeRemainingFormatted.text }}</span>
        </span>
      </span>
    </div>

    <p class="normal-text">Palkinnot tulevat osallistuneilta yrityksiltä ja arvonnan järjestäjältä.
Yhteystietojasi ei luovuteta ulkopuolisille tai käytetä muuhun kuin palkinnonarvontaan.
Palkinnon voittajiin ollaan yhteydessä henkilökohtaisesti.
Onnea Kilbailuun!</p>

    <h2>Päättyneet Kilbailut:</h2>
    <div v-for="quiz in qlist" :key="quiz.code">
      <span v-if="quiz.state != 'running'">
        <router-link :to="{ name: 'Start', params: { code: quiz.code }}">
          <img class="quiz-image" :src="quiz.image" :alt="quiz.code">
        </router-link><br>
      </span>
    </div>

    <h2>Palautetta osallistujilta:</h2>

    <div id="feedback">
      <div class="feedback-line" v-for="(feedback, index) in feedbackList" :key="index">
        <span class="normal-text">{{ feedback }}</span><br>
      </div>
    </div>

    <img class="entrepreneur-logo" src="@/assets/member-company.png" alt="Yrittäjät jäsenyritys">

    <div class="policy-link">
      <router-link :to="{ name: 'Policy'}" class="large-text undecorated-link">
        Rekisteri- ja tietosuojaseloste
      </router-link>
    </div>

  </div>
</template>

<script>
import common from '@/mixins/common'
//import WarningBox from '@/components/WarningBox'

export default {
  name: 'Home',
  mixins: [ common ],  
  components: {
    //WarningBox,
  },  
  data() {
    return {
      qlist: [],
      feedbackList: [],
      hasSave: false,
      preview: false,
      savedState: '',
      savedStateImage: null,
      savedStats: {}
    }
  },
  methods: {
    runningQuizNotEnded() {
      if (this.hasSave === true && this.savedStats.answers <= this.savedStats.total) {
        return true
      } else {
        return false
      }
    },
    hasSavedState() {
      var saved = localStorage.getItem('savedState')
      if (saved !== null) {
        this.savedState = saved
        this.savedStateImage = localStorage.getItem('savedStateImage')
        this.getSaveStats(saved)
        return true
      } else {
        return false
      }
    },
    hasRunningQuiz() {
      for(var quiz in this.qlist) {
        if (this.qlist[quiz].state == 'running') return true
      }
      return false
    },
    isQualified() {
      if (this.savedStats.points >= this.savedStats.required ||
          this.savedStats.answers >= this.savedStats.total) {
        return true
      } else {
        return false
      }
    },
    getSaveStats(code) {
      var state = JSON.parse(localStorage.getItem(code + '_state'))
      if (state === null) {
        return false
      }
      console.log('preview:', state.preview)
      if (state.preview === true) {
        this.preview = true
      }
      var total = (state.quiz.questions === undefined) ? 0 : state.quiz.questions.length
      this.savedStats = {
        required: parseInt(state.requiredPoints),
        points: state.points,
        answers: Object.keys(state.answers).length,
        total: total
      }
    },    
    rmSave(code) {
      var closeEl = document.getElementById('save-close')
      closeEl.style.display = 'none'
      var overlayEl = document.getElementById('save-overlay')
      overlayEl.style.display = 'none'
      var previewEl = document.getElementById('preview')
      if (previewEl) {
        previewEl.style.display = 'none'
      }

      var el = document.getElementById('save-' + code)
      el.classList.add('width-transition')
      el.style.width = 0 + 'px'
      setTimeout(this.realRmSave, 500)
    },
    realRmSave() {
      console.log('removing saved state')
      this.removeSavedState(this.savedState)
      this.hasSave = false
      this.savedState = ''
      this.savedStats = {}
    },
    getTimeRemaining(endtime) {
      const total = endtime - Date.parse(new Date());
      const seconds = Math.floor( (total/1000) % 60 );
      const minutes = Math.floor( (total/1000/60) % 60 );
      const hours = Math.floor( (total/(1000*60*60)) % 24 );
      const days = Math.floor( total/(1000*60*60*24) );

      return {
        total,
        days,
        hours,
        minutes,
        seconds
      }
    },    
    calculateRemainingTimes() {
      for(var quiz in this.qlist) {
        var ends = this.qlist[quiz].ends
        this.qlist[quiz].timeRemaining = this.getTimeRemaining(new Date(ends))
        this.qlist[quiz].timeRemainingFormatted = this.formatTimeRemaining(this.qlist[quiz].timeRemaining)
      }
    },
    formatTimeRemaining(time) {
      if (time.days > 1) {
        return {
          'time': time.days,
          'text': 'päivää jäljellä'
        }
      } else if (time.days == 1) {
        return {
          'time': time.days,
          'text': 'päivä jäljellä'
        }
      } else if (time.hours > 1) {
        return {
          'time': time.hours,
          'text': 'tuntia jäljellä'
        }
      } else if (time.hours == 1) {
        return {
          'time': time.hours,
          'text': 'tunti jäljellä'
        }
      } else if (time.minutes > 1) {
        return {
          'time': time.minutes,
          'text': 'minuuttia jäljellä'
        }
      } else {
        return {
          'time': time.minutes,
          'text': 'minuutti jäljellä'
        }        
      }
    },
    getRemainingTimeStyle(code) {
      var remainingTime = this.qlist[code].timeRemaining
        if (remainingTime.days < 5) {
          return 'days-left-urgent'
        } else if (remainingTime.days < 15) {
          return 'days-left-hurry'
        } else {
          return 'days-left-normal'
        }      
    },
  },
  mounted() {
    window.scrollTo(0, 0)

    this.hasSave = this.hasSavedState()
    this.$api.cachedGet('/kilbailu-data', (response) => {
      this.qlist = response.data
      this.calculateRemainingTimes()
    })

    this.$api.get('/feedback', (response) => {
      this.feedbackList = response.data
    })
  }
}
</script>

<style scoped>
#qualified-warning {
  margin-top: 20px;
}
#app-root {
  width: 800px;
  margin: auto auto;
}
.quiz-image {
  width: 600px;
  border-radius: 20px;
  border: 2px solid #4e044d;
}
.width-transition {
  transition: width 0.5s;  
}
.nav-top-img {
  float: right;
  margin-top: 20px;
  width: 55px;
}
.policy-link {
  margin-bottom: 50px;
}
h1 {
  clear: right;
  font-size: 3em;
}
h2 {
  color: #50044f;
}
#feedback {
  margin-bottom: 40px;
}
.feedback-line {
  margin: 5px;
}
.entrepreneur-logo {
  width: 200px;
  margin-bottom: 30px;
}
.days-left-urgent {
  color: red;
}
.days-left-hurry {
  color: #e3b50e;
}
.days-left-normal {
  color: green;
}
.stats {
  font-size: 1.3em;
  font-weight: bold;
  max-width: 600px;
}
.save-container {
  position: relative;
  width: fit-content;
  margin: auto auto;
}
.remove {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 50px;
  z-index: 20;
}
.in-progress-ribbon {
  position: absolute;
  width: 150px;
  top: 0px;
  left: 0px;
  z-index: 10;
}
.quiz-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  border: none;
}
#preview {
  z-index: 15;
}
#save-overlay {
  z-index: 10;  
}
@media screen and (max-width: 860px) {
  #app-root {
    width: 90vw;
  }
  .remove {
    right: 1vw;
    top: 1vw;
    width: 10vw;
  }
  .quiz-image, .save-container {
    width: 70vw;
  }
  .in-progress-ribbon {
    width: 15vw;
  }
}
</style>
