<template>
  <div class="question">
    <div class="question-item" :id="'question-item-' + question.id">
      <a target="_blank" :href="question.url">
        <img :id="'question-image-' + question.id" ref="questionImg" class="question-image" :src="question.image">
      </a>
      <h2 class="question-text" v-html="question.question"></h2>
      <p class="ans-opt-wrapper" v-for="(answer, ans_i) in question.answers" :key="ans_i">
        <button :id="'ans-opt-' + question.id + '-' + ans_i" class="ans-opt" @click="$emit('checkAns', question.id, ans_i)">
          <span class="btn-text">{{ answer }}</span>
        </button>
      </p>          
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      required: true
    },
    code: {
      type: String,
      required: true
    }    
  },
  methods: {
  }
}
</script>

<style scoped>
.ans-opt {
  position: relative;
}  
.ans-opt:hover {
  animation: grow 0.2s;
  animation-fill-mode: forwards;
}
.ans-opt {
  max-width: 400px;
  min-width: 400px;
  font-size: 1.3em;
  font-weight: bold;
  color:              #000000;
  border-radius: 5px;  
  padding: 8px;
}
.ans-opt-orig {
  max-width: 400px;
  min-width: 400px;
  font-size: 1.3em;
  font-weight: bold;
  color:              #000000;
  background-color:   #F6C2F4;
  border: 1px solid #512f51;
  border-radius: 20px;
  outline: none;
  box-shadow: #FFFFFF 3px 3px 3px  inset;
  background-image: linear-gradient(to bottom, #F6C2F4, #D0D0D0);
  padding: 7.5px;
}
.question-nav-btn {
  font-size: 1.3em;
  font-weight: bold;
  color:              #000000;
  background-color:   #F6C2F4;
  border: 1px solid #512f51;
  outline: none;
  box-shadow: #FFFFFF 3px 3px 3px  inset;
  background-image: linear-gradient(to bottom, #F6C2F4, #D0D0D0);
  padding: 7.5px;  
}
.option_correct {
    animation: grow 0.2s;
    animation-fill-mode: forwards;
}

.option_correct:hover {
    animation: grow 0.2s;
    animation-fill-mode: forwards;
}
.option_incorrect {
    text-decoration: line-through;
    border: 1px solid #aeaeae;
    opacity: 0.5;

    background-image: linear-gradient(to bottom, #aeaeae, #4c4c4c);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#aeaeae, endColorstr=#4c4c4c);
}
@keyframes grow_big {
    100% {
        transform:                      scale(1.3);
        max-width: 400px;
        z-index: 3;
    }
}
@keyframes grow {
    100% {
        transform:                      scale(1.2);
        max-width: 400px;
        z-index: 3;
    }
}
@keyframes grow_little {
    100% {
        transform:                      scale(1.05);
        max-width: 400px;
        z-index: 3;
    }
}
.question-item {
  text-align: center;
  max-width: 700px;
  margin: auto auto;
  user-select: none;
}
.question-image {
  max-width: 600px;
  max-height: 450px;
}
.question-text {
  margin: auto auto;
  max-width: 600px;
}
@media screen and (max-width: 860px) {
  .btn-text {
    font-size: 4vw;
  }
  .ans-opt {
    min-width: 60vw;
    max-width: 60vw;
  }
  .question-nav-btn {
    font-size: 5vw;
  }
  .question-image, .question-item, #banner-img {
    width: 90vw;
  }
}
</style>
