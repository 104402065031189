<template>
  <div id="points-meter-container">
    <div id="points-number">
      <span id="points" class="points">{{ points }}</span>/<span id="total">12</span><br>
      <span id="extra-points" class="extra-points">
        <span v-if="extraPoints > 0">
          +{{ extraPoints }}
        </span>&nbsp;
      </span>
    </div>
    <div id="points-bar">
      <img ref="mercury" id="points-mercury" src="@/assets/mercury.png">
      <img id="points-meter" src="@/assets/points-meter-blue.png">
      <img ref="star" id="points-star" src="@/assets/star.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PointsMeter',
  data() {
    return {
      points: 0,
      extraPoints: 0
    }
  },
  methods: {
    increasePoint() {
      this.animate()
      this.points++
      if (this.points > 12) {
        this.points = 12
        this.extraPoints++
      }
      this.draw()
    },
    animate() {
      var el
      if (this.points >= 12) {
        el = document.getElementById('extra-points')
      } else {
        el = document.getElementById('points')
      }
      el.classList.add('points-large')
      setTimeout(() => {
        el.classList.remove('points-large')
      }, 200)
    },
    setPoints(n) {
      if (n > 12) {
        this.extraPoints = n - 12
        this.points = 12
      } else {
        this.points = n
      }
      this.draw()
    },
    draw() {
      if (this.points > 12) this.points = 12
      this.$refs['mercury'].height = (this.points * 50 + 50);
      this.$refs['star'].style.bottom = (this.points * 50 + 50) + "px"
      setTimeout(this.shake, 200, this.points)
    },
    shake(p) {
      if (this.$refs['star'] === null) return
      this.$refs['star'].classList.add('shaking')
      this.$refs['mercury'].height = p * 50
      this.$refs['star'].style.bottom = (p * 50) + "px"
      setTimeout(this.rmshake, 500)
    },
    rmshake() {
      if (this.$refs['star'] === null) return
      this.$refs['star'].classList.remove('shaking')      
    }
  },
}
</script>

<style scoped>
.points {
  font-size: 2em;
  font-weight: bold;
  color: green;
  transition: font-size 0.2s;
}
.extra-points {
  line-height: 0px;
  font-size: 1.3em;
  font-weight: bold;
  color: green;
  transition: font-size 0.2s;
}
.points-large {
  font-size: 7em;
  text-shadow: 0 0 10px #ff0000, 0 0 20px #0000ff;
}
#total {
  font-size: 1.3em;
  font-weight: bold;
}
#points-number {
  position: absolute;
  top: 630px;
  left: -5px;
  z-index: 14;
}
#points-meter-container {
  position: absolute;
  top: 50px;
  left: 0px;
  float: left;
}
#points-bar {
  position: relative;
}
#points-mercury {
  position: absolute;
  left: 0px;
  bottom: 12px;
  width: 30px;
  z-index: 10;
  transition: height 0.5s;
}
#points-meter {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 12;
}
#points-star {
  position: absolute;
  left: -2px;
  bottom: 4px;
  z-index: 13;
  transition: bottom 0.5s;
}
.shaking {
    animation: shake 0.3s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
@media screen and (max-width: 860px) {
  #total {
    font-size: 2em;
  }
  #points-meter-container {
    left: 20px;
  }
  .points {
    font-size: 3em;
  }
  .extra-points {
    line-height: 20px;
    font-size: 2em;
  }
  .points-large {
    font-size: 10em;
  }  
  #points-number {
    position: absolute;
    bottom: -85px;
    left: -20px;
  }
}
</style>
